<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Tarih"
          label-for="date"
        >
          <validation-provider
            #default="{ errors }"
            name="Tarih"
            rules="required"
          >
            <b-form-datepicker
              id="entry_date"
              v-model="dataItem.pdate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Durum Tipi"
          label-for="id_com_damage_tracking_status"
        >
          <validation-provider
            #default="{ errors }"
            name="Durum Tipi"
            rules="required"
          >
            <v-select
              id="id_com_damage_tracking_status"
              v-model="dataItem.id_com_damage_tracking_status"
              label="title"
              :reduce="item => item.id"
              :options="trackingStatuses"
              placeholder="Seçiniz"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group
      label="Durum Notu"
      label-for="notes"
    >
      <b-form-textarea
        v-model="dataItem.notes"
        placeholder="Durum Notu"
      />
    </b-form-group>
    <pre-loading
      v-if="uploading"
      class="my-2"
    />
    <b-form-group
      v-if="!uploading"
      label="Doküman"
      label-for="upload_file"
    >
      <b-form-file
        v-model="dataItem.upload_file"
        placeholder="Bir dosya seçin veya buraya bırakın..."
        drop-placeholder="Dosya buraya bırakın..."
        browse-text="Dosya Seçin"
      />

    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import {
  BFormDatepicker,
  BFormGroup, BFormTextarea, BRow,
  BCol, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'LineForm',
  components: {
    PreLoading,
    BFormFile,
    BFormDatepicker,
    vSelect,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    BRow,
    BCol,
  },
  data() {
    return {
      required,
      locale: 'tr',
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['damageTrackingLine/getData']
    },
    trackingStatuses() {
      return this.$store.getters['damageTrackingStatus/getDataList']
    },
    uploading() {
      return this.$store.getters['damageTrackingLine/uploading']
    },
  },
  created() {
    this.getStatuses()
  },
  methods: {
    getStatuses() {
      return this.$store.dispatch('damageTrackingStatus/dataList')
    },
  },
}
</script>
