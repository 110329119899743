<template>
  <b-table
    v-if="tableData.length > 0"
    striped
    hover
    responsive
    :fields="fields"
    :items="tableData"
  >
    <template #cell(filename)="data">
      <div v-if="data.item.tracking_status">
        Durum Dokümanı
        <div><small class="text-warning">{{ data.item.title }}</small></div>
        <small class="text-primary">{{ data.item.tracking_status }}</small>
      </div>
      <div v-else-if="data.item.repair_status">
        Parça Tamir Takibi Dokümanı
        <div><small class="text-warning">{{ data.item.title }}</small></div>
        <small class="text-primary">{{ data.item.repair_status }}</small>
      </div>
      <div v-else>
        Takip Kartı Dokümanı
        <div><small class="text-warning">{{ data.item.title }}</small></div>
        <small class="text-primary">{{ data.item.repair_status }}</small>
      </div>
    </template>
    <template #cell(created)="data">
      {{ data.item.created? moment(data.item.created).format('DD.MM.YYYY') : '-' }}
    </template>
    <template #cell(control)="data">
      <b-button
        variant="success"
        class="btn-icon mr-1"
        :href="baseURL + data.item.filename"
        target="_blank"
        pill
      >
        <FeatherIcon icon="EyeIcon" />
      </b-button>
      <b-button
        variant="danger"
        class="btn-icon"
        pill
        @click="deleteData(data.item.id)"
      >
        <FeatherIcon icon="TrashIcon" />
      </b-button>
      <b-button
        variant="primary"
        class="btn-icon ml-1"
        :to="'/damage-tracking/document/edit/' + data.item.id"
        pill
      >
        <FeatherIcon icon="EditIcon" />
      </b-button>
    </template>
  </b-table>
  <b-alert
    v-else
    show
    class="ml-2 mr-2"
  >
    <div class="alert-body text-center">
      <FeatherIcon icon="InfoIcon" />
      Doküman verisi bulunmamaktadır.
    </div>
  </b-alert>
</template>
<script>

import {
  BTable, BButton, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'DocumentListTable',
  components: {
    BAlert,
    BTable,
    BButton,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
      require: true,
    },
    deleteData: {
      type: Function,
      default: null,
      require: true,
    },
  },
  data() {
    return {
      baseURL: `${this.$store.state.app.baseURL}/media/damage_tracking_documents/`,
      userData: JSON.parse(localStorage.getItem('userData')),
      allowedUpdate: ['1', '17', '18', '19'],
      fields: [
        {
          key: 'filename',
          label: 'Doküman',
          tdClass: 'text-nowrap font-small-3',

        },
        {
          key: 'username',
          label: 'Danışman',
          tdClass: 'text-nowrap font-small-3',

        },
        {
          key: 'created',
          label: 'Tarih',
          tdClass: 'text-nowrap font-small-3',

        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '140px' },
          tdClass: 'width-100 text-nowrap font-small-3',

        },
      ],
    }
  },
  methods: {
    // deleteData(id) {
    //   this.$swal({
    //     title: this.$store.state.app.removeTitle,
    //     text: this.$store.state.app.removeMessage,
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: this.$store.state.app.removeConfirm,
    //     cancelButtonText: this.$store.state.app.removeCancel,
    //     customClass: {
    //       confirmButton: 'btn btn-success',
    //       cancelButton: 'btn btn-danger ml-1',
    //     },
    //     buttonsStyling: false,
    //   }).then(result => {
    //     if (result.value) {
    //       this.$store.dispatch('damageTrackingDocument/removeData', id)
    //         .then(res => {
    //           if (res) {
    //             this.$swal({
    //               icon: 'success',
    //               title: this.$store.state.app.removeResultTitle,
    //               text: this.$store.state.app.removeResultMessage,
    //               confirmButtonText: this.$store.state.app.removeResultClose,
    //               customClass: {
    //                 confirmButton: 'btn btn-success',
    //               },
    //             })
    //             this.$store.dispatch('damageTrackingDocument/dataList', {
    //               where: {
    //                 'com_damage_tracking_documents.id_com_damage_tracking': this.$route.params.id,
    //               },
    //             })
    //           }
    //         })
    //     }
    //   })
    // },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
